exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biography-js": () => import("./../../../src/pages/biography.js" /* webpackChunkName: "component---src-pages-biography-js" */),
  "component---src-pages-essays-press-js": () => import("./../../../src/pages/essays-press.js" /* webpackChunkName: "component---src-pages-essays-press-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-single-exhibit-template-js": () => import("./../../../src/templates/singleExhibit-template.js" /* webpackChunkName: "component---src-templates-single-exhibit-template-js" */),
  "component---src-templates-single-work-template-js": () => import("./../../../src/templates/singleWork-template.js" /* webpackChunkName: "component---src-templates-single-work-template-js" */)
}

